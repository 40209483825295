import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"

import PartnerList from "../components/portfolio/partnerList"

import "./sector.scss"

import Arrow from "../images/arrow.svg"
import Bg1 from "../images/sector_bg1.svg"
import Bg2 from "../images/sector_bg2.svg"

const NavigationBar = ({ sectors, closeTo }) => {
  return (
    <div className="navigation-bar">
      <div className="back-navigation">
        <Link
          to={closeTo}
          state={{
            noScroll: true,
          }}
        >
          <Arrow />
          <div>Vissza</div>
        </Link>
      </div>
      <div>
        {sectors.map(s => (
          <Link
            key={s.key}
            to={`/portfolio/${s.key}`}
            state={{
              modal: true,
              noScroll: true,
            }}
            activeClassName="active-link"
          >
            {s.title}
          </Link>
        ))}
      </div>
    </div>
  )
}

const Sector = ({ pageContext }) => {
  const { sector, partners, sectors } = pageContext
  const backLinkRef = useRef(null)

  useEffect(() => {
    if (backLinkRef && backLinkRef.current) {
      backLinkRef.current.click()
    }
  }, [])

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) =>
        modal ? (
          <div className="sector-modal-outer">
            <div className="sector-content">
              <NavigationBar sectors={sectors} closeTo={closeTo} />
              <div className="sector-details">
                <PartnerList
                  key={sector.key}
                  sector={sector}
                  partners={partners.filter(
                    p => p.sector && p.sector.key === sector.key
                  )}
                  more={false}
                />
                <span>
                  <span>
                    <Bg1 />
                  </span>
                  <span>
                    <Bg2 />
                  </span>
                </span>
              </div>
            </div>
          </div>
        ) : (
          <Link ref={backLinkRef} to="/portfolio" />
        )
      }
    </ModalRoutingContext.Consumer>
  )
}

export default Sector
