import React from "react"
import { Link } from "gatsby"

import "./partnerList.scss"

import Arrow from "../../images/arrow_simple.svg"

const PartnerList = ({ sector, partners, more = true }) => {
  return (
    <div className={`sector-column ${!more ? "full-list" : ""}`}>
      <h4>{sector.title}</h4>
      <div className="partner-list">
        {partners
          .filter(p => p.sector && p.sector.key === sector.key)
          .sort((a, b) => a.name.localeCompare(b.name))
          .slice(0, more ? 4 : partners.length)
          .map(p => (
            <Link
              to={`/portfolio/${p.name}`}
              state={{
                modal: true,
              }}
              key={p.name}
            >
              <div className="partner">
                <div className="partner-name">{p.name}</div>
                <div className="partner-desc">
                  {p.description ? p.description.split("\n")[0] : ""}
                </div>
              </div>
            </Link>
          ))}
      </div>
      {more && (
        <Link
          to={`/portfolio/${sector.key}`}
          state={{
            modal: true,
          }}
          className="more-partners"
        >
          <div className="more-partners-text">Továbbiak megjelenítése</div>
          <Arrow />
        </Link>
      )}
    </div>
  )
}

export default PartnerList
